@use "stylesheets/colors.scss" as *;

.lister-form-options {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    & > span {
        display: inline-block;
        padding: .5rem;
    }

    .small-button {
        * {
            margin: 0 .25rem;
        }
        select, input {
            padding: 0 .25rem;
            height:auto;
        }
    }
}