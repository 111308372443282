@use './colors.scss' as *;
@use "../stylesheets/theme-colors.scss" as *;
$headerFont:  'Reem Kufi Ink';
$textFont: 'Montserrat';

$lineHeight: 1.5;

body {
	font-size: 14px;
	font-family: $textFont;
    color: $mainTextColor;
    font-weight: 400;
}
* { font-family: $textFont }

h1 {
	font-size: 48px;
    font-family: $headerFont;
    line-height: $lineHeight;
    font-weight: bold;
    color: $primary-color;
}
h2 {
	font-size: 32px;
    font-family: $headerFont;
    line-height: $lineHeight;
    font-weight: normal;
}
h3 {
	font-size: 20px;
    font-family: $textFont;
    line-height: $lineHeight;
    font-weight: 600;
    margin-top:20px;
    margin-bottom:7px;
}
h4 {
	font-size: 16px;
    font-family: $textFont;
    line-height: $lineHeight;
    font-weight: 500;
    margin-top:14px;
    margin-bottom:7px;
}
h5 {
	font-size: 14px;
    font-family: $textFont;
    line-height: $lineHeight;
}
h6 {
	font-size: 14px;
    font-family: $headerFont;
    line-height: $lineHeight;
    font-weight: normal;
}
p {
	font-size: 14px;
    line-height: $lineHeight;
    margin-bottom: 6px;
}

@media (max-width: 600px) {
    h1 {
        font-size: 32px;
        font-family: $headerFont;
        line-height: $lineHeight;
        font-weight: bold;
    }
    h2 {
        font-size: 26px;
        font-family: $headerFont;
        line-height: $lineHeight;
        font-weight: normal;
    }
}

:export {
    headerFont: $headerFont;
    textFont: $textFont;
}