@use './reset' as *;

@use '~bootstrap/scss/bootstrap' as *;

/* The color palette */
@use './colors' as *;
@use './theme-colors' as *;

/* Font-families & sizes for all fonts */
@use './fonts.module.scss' as *;

@use './forms' as *;
@use './linksAndButtons' as *;
@use './formatting' as *; //Quick classes for text alignment, margin, and padding.

body {
    background-color: $mainBackgroundColor;
}