@use "stylesheets/colors.scss" as *;
@use "stylesheets/fonts.module.scss" as *;

.table-of-contents {
    color: $l-grey-color;
    font-family: $headerFont;
    font-size: 16px;
    position: fixed;
    width: 100%;
    top: 60px;
    text-align: left;
    cursor: pointer;
    z-index: 100;

    label {
        cursor: pointer;
        display: block;
    }
    label:hover {
        color: $ll-light-color;
    }

    .toc-panel {
        padding: 0.5rem;
    }

    #toc-header {
        background-color: $d-primary-color;
        a { color: $l-grey-color; }
        .container {
            
        display: flex;
        align-items: center;
        justify-content: space-between;
        }

    }
    .toc-body {
        height: calc(100vh - 100px);
        overflow: scroll;
        overscroll-behavior: none;
        background-color: $primary-color;
        padding:1rem 0rem 4rem;
    }

    h5 {
        text-align: center;
        font-size:22px;
        font-weight: bold;
    }

    #toc-parts-list {
        width: fit-content;
        & > div {
            background-color: rgba($light-color, .1);
            margin: .25rem 0;
            padding: .5rem 1rem;
        }
    }
}

.side-contents {
    position: sticky; 
    top: 125px; 
    max-height: 80vh; 
    overflow-y: scroll;
    overscroll-behavior: none;

    #toc-parts-list > div {
        padding: .5rem !important;
        background: $l-primary-color;
        &.format-link:hover {
            background: $ll-primary-color !important;
        }
    }
    #toc-parts-list .toc-parts-slot {
        background: $primary-color;
        color: $light-color;

    }
    h5 { 
        font-size:18px;
        font-weight: bold;
    }

}