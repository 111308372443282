html, body {
    overscroll-behavior-x: none;
}

.dast-container {
    --data-cell-width: 100px;
    --data-cell-height: 76px;
    position:relative;

    max-width: 100%;
    max-height: 100%;
    width: 100%;
    height: 100%;

    border: 1px solid black;
}

.dast-menu-background {
    background-color: rgba(0,0,0,.9);
    position: absolute;

    max-width: 100%;
    max-height: 100%;
    width: 100%;
    height: 100%;
    z-index:10;

    padding: 1rem;
    top: 0;
    left: 0;
}
.dast-options-button {
    position:absolute;
    height: var(--data-cell-height);
    width: var(--data-cell-width);
    display: flex;
    align-items: center;
    justify-content: center;
}

.dast-options {
    color: white;
    display:inline-block;
}
.dast-options > span {
    display:inline-block;
    padding: .25rem;
    margin:.25rem;
    background: rgba(255,255,255,.1);
}

.dast-table-container {
    display: flex;
    font-size:12px;
    
    max-width: 100%;
    max-height: 100%;
    width: 100%;
    height: 100%;
}

.item-wide {
    display: flex;
    flex-wrap: nowrap;
    width: fit-content;
}

.dast-label-x {
    width: 100%;
    overflow: scroll;
    scrollbar-width: none;
}

.dast-label-y {
    margin-top: var(--data-cell-height);
    max-height: calc(100% - var(--data-cell-height));
    width: var(--data-cell-width);
    overflow: scroll;
    scrollbar-width: none;
}

.dast-right {
    width: calc(100% - var(--data-cell-width));
    height: calc(100% - var(--data-cell-height));
    max-width: calc(100% - var(--data-cell-width));
    max-height: calc(100% - var(--data-cell-height));
}


.dast-data-container {
    max-width: 100%;
    max-height: 100%;
    width: 100%;
    height: 100%;

    overflow: auto;
}

@media (min-width: 912px){
    .dast-data-container {
        width: calc(100% + 17px);
        height: calc(100% + 17px);
        max-width: calc(100% + 17px);
        max-height: calc(100% + 17px);
    }
}


.data-cell {
    height: var(--data-cell-height);
    width: var(--data-cell-width);
    display: flex;
    justify-content: center;
    padding: 6px;
    border: 1px solid #999;
    overflow-y:auto;
    overflow-x:hidden;
    line-height:1.1;
    background: #bbb;
    text-align:center;
}

.dr-even {
    background-color: #eee;
}

.dr-odd {
    background-color: #ddd;
}


.dast-label-x::-webkit-scrollbar,
.dast-label-y::-webkit-scrollbar {
    width: 0px;
    /* Mostly for vertical scrollbars */
    height: 0px;
    /* Mostly for horizontal scrollbars */
}