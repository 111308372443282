$ll-primary-color: #b68bac;
$l-primary-color: #8D6584;
$primary-color: #6D4369;
$d-primary-color: #51284f;
$dd-primary-color: #51284f;

$ll-secondary-color: #b0e3e7;
$l-secondary-color: #84C7CD;
$secondary-color: #50A4AB;
$d-secondary-color: #2e7980;
$dd-secondary-color: #1F4B4F;

$ll-light-color: #ffffff;
$l-light-color: #F7F6F6;
$light-color: #F0F0F0;
$d-light-color: #D9D3D9;

$ll-grey-color: #d8d5da;
$l-grey-color: #c4bdc9;
$grey-color: #AFA1B7;
$d-grey-color: #817588;

$l-dark-color: #0E252B;
$dark-color: #0E252B;
$d-dark-color: #0E252B;
$dd-dark-color: #0E252B;