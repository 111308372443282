@use '../stylesheets/colors.scss' as *;

.footer {
    bottom:0;
    padding:.25rem;
    margin-top:4rem;
}

.footer-content {
    color: $d-grey-color;
    font-weight:bold;
    a {
        color: $d-grey-color;
        font-weight:bold;
    }
}