@use "stylesheets/colors.scss" as *;

#reader-view {
    p { margin-bottom: 12px; line-height:1.5;}
    h4 { margin-top: 32px; }
    .control-title-bar {
        position: relative;
        margin: 0.5rem 0;
    }

    .control-title-bar h2 {
        margin: 0;
        font-size:28px;
    }

    .control-main-bar {
        text-align: center;
        padding: 1rem;
    }

    .ctb-part {
        .control-main-bar {
            background: $d-primary-color;
            color: $light-color;
        }
        .control-bar-up > span,
        .control-bar-left,
        .control-bar-right {
            background: $d-primary-color;
            color: $light-color;
        }

        .control-bar-up > span:hover,
        .control-bar-right:hover,
        .control-bar-left:hover,
        .control-bar-collapse:hover {
            background-color: $primary-color;
        }
    }
    .ctb-chapter {
        .control-main-bar {
            background: $l-primary-color;
            color: $light-color;
        }
        .control-bar-up > span,
        .control-bar-left,
        .control-bar-right {
            background: $l-primary-color;
            color: $light-color;
        }

        .control-bar-up > span:hover,
        .control-bar-right:hover,
        .control-bar-left:hover,
        .control-bar-collapse:hover {
            background-color: $primary-color;
        }
    }
    .ctb-verse {
        &:hover .control-main-bar {
            background: $grey-color;
        }
        .control-bar-up > span,
        .control-bar-left,
        .control-bar-right {
            background: $grey-color;
            color: $light-color;
        }

        .control-bar-up > span:hover,
        .control-bar-right:hover,
        .control-bar-left:hover,
        .control-bar-collapse:hover {
            background-color: $primary-color;
        }
    }

    .control-bar-up,
    .control-bar-left,
    .control-bar-right,
    .control-bar-collapse {
        display: none;
        cursor: pointer;
    }
    .control-bar-left,
    .control-bar-right,
    .control-bar-collapse {
        font-size: 20px;
    }

    .control-bar-collapse,
    .control-bar-collapse > span {
        width: 50px;
        text-align: center;
    }

    .control-bar-up {
        position: absolute;
        width: 100%;
        top: -32px;
        font-size: 16px;
    }

    .control-bar-up > span {
        padding: 0.5rem;
    }

    .control-bar-left,
    .control-bar-right {
        position: absolute;
        width: 50px;
        height: 100%;
    }

    .control-bar-left {
        left: -50px;
    }
    .control-bar-left > span,
    .control-bar-right > span {
        padding: 1rem;
    }
    .control-bar-right {
        right: -50px;
    }

    .options-hover {
        display: inline-block;
        border: 1px solid black;
        padding: 0.5rem;
        margin: 0.5rem;
    }
    .options-hover > span {
        display: none;
    }
    .options-hover:hover > span {
        display: inline-block;
        border: 1px solid black;
        padding: 0.5rem;
        margin: 0.5rem;
    }

    .verse-info-options {
        display: flex;
        justify-content: space-between;
    }

    .dropspace {
        width: 110%;
        height: 12px;
        margin-left: -5%;
    }

    .dragging-part .dropspace-part {
        display: block;
    }

    .dropspace-hover {
        height: 48px;
        background-color: $secondary-color;
    }

    

    .stats-box > div {
        padding-bottom: .5rem;
    }

}
