@use "stylesheets/colors.scss" as *;

.search-option {
	background-color: $l-primary-color;
	color: $light-color;
	padding: .5rem;
	height:100%;
}

.search-option-selected {
	background-color: $primary-color;
}
.search-results-header {
	background-color: $d-primary-color;
	color: $light-color;
	padding: .5rem;
	margin:0;
}

.search-box {
	padding: 2rem;
	background: $primary-color;

	h3, p { color: $light-color; margin: 0; }
}

@media (min-width: 912px) {
	.search-box {
		padding: 4rem;
	}
	.search-search-box {
		display: flex;
		flex-wrap: nowrap;
		align-items: center;
		
		& > input {
			flex-grow:12;
		}
	}
}

.search-result {
	padding:2rem;
	text-align:left;
	background: $l-grey-color;
	border-bottom: 2px solid $grey-color;
}