@use "stylesheets/colors.scss" as *;
@use "stylesheets/theme-colors.scss" as *;


a, .format-link {
    text-decoration: none;
    color: $primary-color;
    cursor: pointer;

    &:hover, &:active {
        color: $dd-primary-color;
        font-weight:bold;
    }
}

.format-link.light-link {
    color: $light-color;
    &:hover, &:active {
        color: $ll-light-color;
    }
}

.primary-button, button {
    cursor: pointer;
    padding: .75rem 2rem;
    background: $primary-color;
    color: $light-color;
    border: none;
    box-shadow:
        1px 1px 2px rgba(0, 0, 0, 0.2),
        -1px -1px 2px rgba(255, 255, 255, 0.2);
    display: inline-block;
    font-size: 16px;
    font-weight: 500;
    border-radius: $borderRadius;
    margin: 5px auto;

    &:hover {
        background: $l-primary-color;
        color: $ll-light-color;
    }
    &:active {
        background: $ll-primary-color;
    }
}

.lprimary-button {
    background: $grey-color;
    color: $d-primary-color;
}

.secondary-button {
    background: $secondary-color;
    font-weight:bold;
    
    &:hover {
        background: $l-secondary-color;
    }
    &:active {
        background: $ll-secondary-color;
    }
}
.transparent-button {
    background: transparent;
    border: 2px solid $ll-primary-color;
    font-weight:bold;
    color: $ll-primary-color;
    
    &:hover {
        border-color: $d-primary-color;
        color: $d-primary-color;
        background: transparent;
    }
    &:active {
        border-color: $dd-primary-color;
        color: $dd-primary-color;
        background: transparent;
    }
}

.small-button {
    font-size: 14px;
    padding: 5.5px 0.5rem;
}
.xsmall-button {
    font-size: 12px;
    padding: 2px 4px;
}


.tooltip-inner {
    background: $primary-color;
}
.bs-tooltip-bottom .tooltip-arrow::before {
    border-bottom-color: $primary-color;
}
.bs-tooltip-top .tooltip-arrow::before {
    border-top-color: $primary-color;
}
.bs-tooltip-left .tooltip-arrow::before {
    border-left-color: $primary-color;
}
.bs-tooltip-right .tooltip-arrow::before {
    border-right-color: $primary-color;
}

.secondary .tooltip-inner {
    background: $secondary-color;
}
.secondary.bs-tooltip-bottom .tooltip-arrow::before {
    border-bottom-color: $secondary-color;
}
.secondary.bs-tooltip-top .tooltip-arrow::before {
    border-top-color: $secondary-color;
}
.secondary.bs-tooltip-left .tooltip-arrow::before {
    border-left-color: $secondary-color;
}
.secondary.bs-tooltip-right .tooltip-arrow::before {
    border-right-color: $secondary-color;
}