@use "stylesheets/colors.scss" as *;

.contactMessage-item {    
    padding: 0.5rem;
    background: $l-grey-color;
    margin: 0.5rem;

    .row > div {
        display: flex;
        align-items: center;
    }
}
