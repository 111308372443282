@use "stylesheets/colors.scss" as *;


.resource-select {
    input { 
        width: 100%; 
        height: auto;
        padding:0rem .5rem;
    }

    .primary-button {
        margin: 0;
    }

    .resource-select-box {
        background: $grey-color;
        padding:1rem;
    }

    .resource-select-results {
        max-height: 300px;
        overflow-y: scroll;
        background: $l-grey-color;
    }

    .resource-select-option {
        padding: .25rem;
    }
}