@use "stylesheets/colors.scss" as *;


#book-directory-view {
    .book-sort-banner {
        background: $primary-color;
        padding: 1rem;
        margin: 2rem 0;

        & span {
            color: $d-light-color;
        }
    }

    .book-row-link {
        padding: .25rem;
        margin: .25rem;
        display: block;
        font-size: 16px;
        font-weight: 500;
        color: $d-primary-color;

        &:hover {
            background: $grey-color;

            & .book-order {
                color: $d-primary-color;
            }
        }
    }

    .book-index-row {
        --bs-gutter-x: .25rem;
        align-items: center;
    }

    .book-order {
        font-size: 14px;
        color: $grey-color;
        text-align: left;
    }

    .book-name {
        font-weight: 800;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        text-align: left;
    }
}