@use "stylesheets/colors.scss" as *;

#books-completion-view {
    padding:5rem 0;
    .edit-field { 
        width: 100%;
        
        input, select {
            width: 100%;
            padding: 2px;
        }
    
    }

}
