@use "stylesheets/colors.scss" as *;
@use "stylesheets/fonts.module.scss" as *;

#categories-view {
    .category-outer-container {
        width: 100%;
        height: auto;
        margin: auto;
        overflow: auto;
    }
    .category-inner-container {
        display: block;
    }

    .category-item {
        text-align: left;

        margin: 2rem 0;
        padding: 1rem;

        .category-item-header {
            display: flex;

            justify-content: flex-start;
            align-items: center;
        }
    }
    @media (max-width: 600px) {
        .category-item {
            text-align: center;
            .category-item-header {
                display: block;
            }
        }
    }
}

.category-book-section {
    background-color: $l-primary-color;
    position: relative;

    & a {
        color: $d-light-color;
        padding: 1rem 2rem;
        display: inline-block;
        font-family: $headerFont;
        min-width: max-content;
    }
}
.cbs-trigger {
    position: absolute;
    top: 0;
    right: 0;
    padding: 1rem 2rem;
    background-color: $d-primary-color;
    color: $ll-primary-color;
    cursor: pointer;
}
.cbs-collapsed {
    overflow: hidden;
    display: flex;
    flex-wrap: none;
}
.cbs-showing {
    overflow: visible;
    height: auto;
}
