@use "stylesheets/colors.scss" as *;


.approve-post {
    .edit-field {
        width: 100%;

        input,
        select {
            width: 100%;
        }

        textarea {
            height: 400px;
        }
    }
}

.socialPost-item {
    background: $l-grey-color;
    padding:2rem;
    margin:1rem;
}