.align-center {
    text-align: center;
}
.align-left {
    text-align: left;
}
.align-right {
    text-align: right;
}

.d-flex {
    display: flex;
}
.d-inline-block {
    display: inline-block;
}
.d-inline {
    display: inline;
}
.d-block {
    display: block;
}
.d-none {
    display: none;
}

.pos-rel {
    position: relative;
}

@media (max-width: 912px) {
    .d-lg-only {
        display:none;
    }
}

.z-10 {
    z-index: 10;
}

.margin-center {
    margin-left:auto;
    margin-right:auto;
    display:block;
    width:fit-content;
}

.m {
    margin: .5rem;
}
.m-1 {
    margin: 1rem;
}
.m-2 {
    margin: 2rem;
}
.m-4 {
    margin: 4rem;
}

.mt {
    margin-top: .5rem;
}
.mt-1 {
    margin-top: 1rem;
}
.mt-2 {
    margin-top: 2rem;
}
.mt-4 {
    margin-top: 4rem;
}

.mb {
    margin-bottom: .5rem;
}
.mb-1 {
    margin-bottom: 1rem;
}
.mb-2 {
    margin-bottom: 2rem;
}
.mb-4 {
    margin-bottom: 4rem;
}

.ml {
    margin-left: .5rem;
}
.ml-1 {
    margin-left: 1rem;
}
.ml-2 {
    margin-left: 2rem;
}
.ml-4 {
    margin-left: 4rem;
}

.mr {
    margin-right: .5rem;
}
.mr-1 {
    margin-right: 1rem;
}
.mr-2 {
    margin-right: 2rem;
}
.mr-4 {
    margin-right: 4rem;
}

.p {
    padding: .5rem;
}
.p-1 {
    padding: 1rem;
}
.p-2 {
    padding: 2rem;
}
.p-4 {
    padding: 4rem;
}

.pt {
    padding-top: .5rem;
}
.pt-1 {
    padding-top: 1rem;
}
.pt-2 {
    padding-top: 2rem;
}
.pt-4 {
    padding-top: 4rem;
}

.pb {
    padding-bottom: .5rem;
}
.pb-1 {
    padding-bottom: 1rem;
}
.pb-2 {
    padding-bottom: 2rem;
}
.pb-4 {
    padding-bottom: 4rem;
}

.pl {
    padding-left: .5rem;
}
.pl-1 {
    padding-left: 1rem;
}
.pl-2 {
    padding-left: 2rem;
}
.pl-4 {
    padding-left: 4rem;
}

.pr {
    padding-right: .5rem;
}
.pr-1 {
    padding-right: 1rem;
}
.pr-2 {
    padding-right: 2rem;
}
.pr-4 {
    padding-right: 4rem;
}

.pv {
    padding-top: .5rem;
    padding-bottom: .5rem;
}
.pv-1 {
    padding-top: 1rem;
    padding-bottom: 1rem;
}
.pv-2 {
    padding-top: 2rem;
    padding-bottom: 2rem;
}
.pv-4 {
    padding-top: 4rem;
    padding-bottom: 4rem;
}
.pv-5 {
    padding-top: 5rem;
    padding-bottom: 5rem;
}
