@use "../stylesheets/colors.scss" as *;
@use "../stylesheets/theme-colors.scss" as *;
@use "../stylesheets/fonts.module.scss" as *;

.App {
    padding-top: 58px;
    text-align: center;
    padding-bottom: 58px;
}

.header {
    background: $headerBackgroundColor;
    padding: 0.5rem;
    width: 100%;
    position: fixed;
    top: 0;
    z-index: 1000;
    font-size:16px;

    color: $grey-color;
    a {
        color: $d-grey-color;
        font-weight:bold;
        &:hover { color: $primary-color; }
    }
    @media (min-width: 912px) {
        a {
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0 0.5rem;
        }
    }
    @media (max-width: 911px) {
        .nav-item, .navbar-brand { padding: 0;  }
        .nav-item > a { padding: .375rem 0; display:block; }
        .dropdown-menu.show {
            background: none;
            border: none;
            padding: 0;
            margin: 0 0 1rem;

        }
        .navbar-collapse {
            padding: 1rem 0 2rem;
        }
    }


    
    .dropdown-menu.show a {
        width: 100%;
    }
    .main-home-link {
        display: inline-flex;
        justify-content: center;
        align-items: center;

        color: $primary-color;

        & > img {
            margin: 0.25rem;
            height: 36px;
        }
        .homeText {
            font-size: 28px;
            line-height: 1;
            font-weight: 800;
            font-family: $headerFont;
        }
    }


    .search-button {
        background-color: $secondary-color;
        color:$ll-light-color;
        padding: 0.5rem;
        font-size: 12px;
        border-radius: 100%;
        &:hover {
            background-color: $l-secondary-color;
        }
    }

    .navbar-toggler {
        margin: 0;
    }
}
