@use "stylesheets/colors.scss" as *;

.cmodal-bg {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba($d-grey-color, .5);
    z-index: 10;
    padding: calc(100px + 2rem) 0;
}    

.cmodal-main {
    background: $light-color;
}

.cmodal-header {
    font-size:20px;
    font-weight:bold;
    display:flex;
    justify-content: space-between;
    align-items: center;
    background: $primary-color;
    color: $light-color;
    padding: 1rem;
}

.cmodal-body {
    padding:1rem;
    max-height: 70vh;
    overflow-y: auto;
    overscroll-behavior: none;
}