@use "stylesheets/colors.scss" as *;

.dark-stretchy {
    background-color: $primary-color;
    color: $light-color;
    padding: 1rem;
}

.collapse-main-bar {
    text-align: center;
    padding: 1rem;
    background: $d-primary-color;
    color: $ll-light-color;
    margin: 1rem 0 0;
}
.collapse-body {
    background:$l-grey-color;
    padding: 1rem;
}

.example-section {
    background-color: $l-primary-color;
}