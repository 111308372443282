@use "stylesheets/colors.scss" as *;
@use "stylesheets/fonts.module.scss" as *;

.bottom-the-end {
	color: $grey-color;
	font-size: 64px;
	font-weight: bold;
	font-family: $headerFont;
	margin-top: 4rem;
	margin-bottom: 2rem;
}
.bottom-book-nav {
	margin-bottom: 4rem;
}
.bottom-book-nav > div {
	margin-top: 2rem;
}