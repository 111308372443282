@use "stylesheets/colors.scss" as *;

.edit-field {
    cursor: pointer;
    display: inline-block;
    padding: 8px;
    position: relative;
}

.edit-field.unsaved {

    &>input,
    span,
    textarea {
        outline-color: white !important;
    }
}

.edit-field:hover {
    background: rgba(255, 255, 255, .25);
}

.edit-field-text {
    width: 100%;
}

.edit-field textarea {
    height: 100px;
}


.dynamic-field-tooltip {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 3;
    padding: 0.25rem;

    &>span {
        border: 1px solid $l-primary-color;
        color: $l-primary-color;
        font-size: 12px;
        height: 18px;
        width: 18px;
        border-radius: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}