@page {
    size: 7in 9in;
    margin: 0.5in 0.5in;

    @bottom-center {
        content: counter(page);
    }

    @top-center {
        content: string(title);
        font-size: 12px;
    }


}

#printer-view {

    * {
        color: black;
    }

    #top-center-display {
        string-set: title content(text);
        display: none;
    }

    .toc-chapter {
        padding: .25rem 0;
        border-bottom: 1px solid whitesmoke;
    }

    .toc-chapter a {
        color: black;
    }

    .toc-chapter a::after {
        content: target-counter(attr(href), page);
        float: right;
    }

    .toc-book {
        padding: .25rem 0;
        border-bottom: 1px solid whitesmoke;
    }

    .toc-book a {
        color: black;
    }

    .toc-book a::after {
        content: target-counter(attr(href), page);
        float: right;
    }

    .pagebreak {
        break-after: always;
    }

    .translation-line,
    .verse-line,
    .verse-pair {
        break-inside: avoid;
    }

    #toc-header {
        display: none;
    }

    h1, h2, h3, h4 {
        text-align: center;
    }
}