@use "stylesheets/colors.scss" as *;
@use "stylesheets/theme-colors.scss" as *;

input, textarea, select {
    border: 0;
    outline: 0;
    border-radius: $borderRadius;
    padding: 0.25rem .5rem;
    font-size: 16px;
    outline: 2px solid $primary-color;
    height: 32px;

    &:focus {
        outline-color: $grey-color;
    }
}

.form-pairing {
    display: flex;
    input {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        width: 100%;
    }
    .button {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }
}